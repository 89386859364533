var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "right-con" },
    [
      _c("div", { staticClass: "mian" }, [
        _c(
          "h1",
          [
            _c("img", {
              attrs: {
                src: require("@/assets/image/application/表单.png"),
                alt: "",
              },
            }),
            _c("span", [_vm._v(_vm._s(_vm.$t("lang_form")))]),
            _c("el-button", {
              staticStyle: {
                "margin-left": "10px",
                "vertical-align": "bottom",
              },
              attrs: {
                type: "primary",
                icon: "el-icon-plus",
                size: "mini",
                circle: "",
              },
              on: {
                click: function ($event) {
                  return _vm.addData("form")
                },
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "bottom-line" }),
        _c(
          "ul",
          _vm._l(_vm.formList, function (item, index) {
            return _c(
              "li",
              { key: item.id, style: { background: _vm.liColorS[index % 4] } },
              [
                [
                  item.link.status == 0
                    ? _c(
                        "span",
                        {
                          staticClass: "li-span-edit li-span",
                          attrs: { type: "primary" },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_edit_z")))]
                      )
                    : _vm._e(),
                  item.link.status == 1
                    ? _c(
                        "span",
                        {
                          staticClass: "li-span-pub li-span",
                          attrs: { type: "success" },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_published")))]
                      )
                    : _vm._e(),
                  item.link.status == 2
                    ? _c(
                        "span",
                        {
                          staticClass: "li-span-danger li-span",
                          attrs: { type: "danger" },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_closed")))]
                      )
                    : _vm._e(),
                ],
                _c("p", { staticClass: "li-title" }, [
                  _vm._v(_vm._s(item.link.displayName)),
                ]),
                _c("p", { staticClass: "li-subtitle" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("lang_version_number") + " " + item.link.version
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "p",
                  { staticClass: "li-button" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_edit"),
                          placement: "bottom",
                        },
                      },
                      [
                        item.link.status == 0
                          ? _c("img", {
                              staticClass: "img-left",
                              attrs: {
                                src: require("@/assets/image/application/编辑白.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.editForm(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_application_manage_upgrade"),
                          placement: "bottom",
                        },
                      },
                      [
                        item.link.status == 1
                          ? _c("img", {
                              staticClass: "img-left",
                              attrs: {
                                src: require("@/assets/image/application/升版白.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.upgradeForm(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_design"),
                          placement: "bottom",
                        },
                      },
                      [
                        item.link.status == 0
                          ? _c("img", {
                              staticClass: "img-left",
                              attrs: {
                                src: require("@/assets/image/application/设计白.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.designForm(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_copy"),
                          placement: "bottom",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "img-left",
                          attrs: {
                            src: require("@/assets/image/application/复制.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.copyApplicationLink(item)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_historical_version"),
                          placement: "bottom",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "img-left",
                          attrs: {
                            src: require("@/assets/image/application/历史版本白.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.historyForm(item)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_delete"),
                          placement: "bottom",
                        },
                      },
                      [
                        item.link.status == 0
                          ? _c("img", {
                              staticClass: "img-left",
                              attrs: {
                                src: require("@/assets/image/application/删除.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteForm(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_closed_flow"),
                          placement: "bottom",
                        },
                      },
                      [
                        item.link.status == 1
                          ? _c("img", {
                              staticClass: "img-left",
                              attrs: {
                                src: require("@/assets/image/application/关闭白.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.closeItem(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "mian" }, [
        _c(
          "h1",
          [
            _c("img", {
              attrs: {
                src: require("@/assets/image/application/流程.png"),
                alt: "",
              },
            }),
            _c("span", [_vm._v(_vm._s(_vm.$t("lang_technological_process")))]),
            _c("el-button", {
              staticStyle: {
                "margin-left": "10px",
                "vertical-align": "bottom",
              },
              attrs: {
                type: "primary",
                icon: "el-icon-plus",
                size: "mini",
                circle: "",
              },
              on: {
                click: function ($event) {
                  return _vm.addData("flow")
                },
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "bottom-line" }),
        _c(
          "ul",
          _vm._l(_vm.flowList, function (item, index) {
            return _c(
              "li",
              { key: item.id, style: { background: _vm.liColorS[index % 4] } },
              [
                [
                  item.link.status == 0
                    ? _c(
                        "span",
                        {
                          staticClass: "li-span-edit li-span",
                          attrs: { type: "primary" },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_edit_z")))]
                      )
                    : _vm._e(),
                  item.link.status == 1
                    ? _c(
                        "span",
                        {
                          staticClass: "li-span-pub li-span",
                          attrs: { type: "success" },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_published")))]
                      )
                    : _vm._e(),
                  item.link.status == 2
                    ? _c(
                        "span",
                        {
                          staticClass: "li-span-danger li-span",
                          attrs: { type: "danger" },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_closed")))]
                      )
                    : _vm._e(),
                ],
                _c("p", { staticClass: "li-title" }, [
                  _vm._v(_vm._s(item.link.displayName)),
                ]),
                _c("p", { staticClass: "li-subtitle" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("lang_version_number") + " " + item.link.version
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "p",
                  { staticClass: "li-button" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_edit"),
                          placement: "bottom",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "img-left",
                          attrs: {
                            src: require("@/assets/image/application/编辑白.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              item.link.status == 1
                                ? _vm.editFlowBaseInfo(item)
                                : _vm.editFlow(item)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_application_manage_upgrade"),
                          placement: "bottom",
                        },
                      },
                      [
                        item.link.status == 1
                          ? _c("img", {
                              staticClass: "img-left",
                              attrs: {
                                src: require("@/assets/image/application/升版白.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.upgradeFlow(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_design"),
                          placement: "bottom",
                        },
                      },
                      [
                        item.link.status == 0
                          ? _c("img", {
                              staticClass: "img-left",
                              attrs: {
                                src: require("@/assets/image/application/设计白.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.designFlow(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_copy"),
                          placement: "bottom",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "img-left",
                          attrs: {
                            src: require("@/assets/image/application/复制.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.copyApplicationLink(item)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_historical_version"),
                          placement: "bottom",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "img-left",
                          attrs: {
                            src: require("@/assets/image/application/历史版本白.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.historyFlow(item)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_delete"),
                          placement: "bottom",
                        },
                      },
                      [
                        item.link.status == 0
                          ? _c("img", {
                              staticClass: "img-left",
                              attrs: {
                                src: require("@/assets/image/application/删除.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteFlow(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_closed_flow"),
                          placement: "bottom",
                        },
                      },
                      [
                        item.link.status == 1
                          ? _c("img", {
                              staticClass: "img-left",
                              attrs: {
                                src: require("@/assets/image/application/关闭白.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.closeItem(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "mian" }, [
        _c(
          "h1",
          [
            _c("img", {
              attrs: {
                src: require("@/assets/image/application/打印.png"),
                alt: "",
              },
            }),
            _c("span", [_vm._v(_vm._s(_vm.$t("lang_print")))]),
            _c("el-button", {
              staticStyle: {
                "margin-left": "10px",
                "vertical-align": "bottom",
              },
              attrs: {
                type: "primary",
                icon: "el-icon-plus",
                size: "mini",
                circle: "",
              },
              on: {
                click: function ($event) {
                  return _vm.addData("report")
                },
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "bottom-line" }),
        _c(
          "ul",
          _vm._l(_vm.reportList, function (item, index) {
            return _c(
              "li",
              { key: item.id, style: { background: _vm.liColorS[index % 4] } },
              [
                [
                  item.link.status == 0
                    ? _c(
                        "span",
                        {
                          staticClass: "li-span-edit li-span",
                          attrs: { type: "primary" },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_edit_z")))]
                      )
                    : _vm._e(),
                  item.link.status == 1
                    ? _c(
                        "span",
                        {
                          staticClass: "li-span-pub li-span",
                          attrs: { type: "success" },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_published")))]
                      )
                    : _vm._e(),
                  item.link.status == 2
                    ? _c(
                        "span",
                        {
                          staticClass: "li-span-danger li-span",
                          attrs: { type: "danger" },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang_closed")))]
                      )
                    : _vm._e(),
                ],
                _c("p", { staticClass: "li-title" }, [
                  _vm._v(_vm._s(item.link.displayName)),
                ]),
                _c("p", { staticClass: "li-subtitle-printf" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("lang_creation_time") +
                          " " +
                          _vm.DateUtil.format(
                            new Date(item.createDate),
                            "yyyy-MM-dd hh:mm"
                          )
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "p",
                  { staticClass: "li-button" },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_edit"),
                          placement: "bottom",
                        },
                      },
                      [
                        item.link.status == 0
                          ? _c("img", {
                              staticClass: "img-left",
                              attrs: {
                                src: require("@/assets/image/application/编辑白.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.editReport(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_application_manage_upgrade"),
                          placement: "bottom",
                        },
                      },
                      [
                        item.link.status == 1
                          ? _c("img", {
                              staticClass: "img-left",
                              attrs: {
                                src: require("@/assets/image/application/升版白.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.upgradeForm(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_copy"),
                          placement: "bottom",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "img-left",
                          attrs: {
                            src: require("@/assets/image/application/复制.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.copyApplicationLink(item)
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_delete"),
                          placement: "bottom",
                        },
                      },
                      [
                        item.link.status == 0
                          ? _c("img", {
                              staticClass: "img-left",
                              attrs: {
                                src: require("@/assets/image/application/删除.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeReport(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_historical_version"),
                          placement: "bottom",
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "img-left",
                          attrs: {
                            src: require("@/assets/image/application/历史版本白.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.historyForm(item, "readonly")
                            },
                          },
                        }),
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.$t("lang_closed_flow"),
                          placement: "bottom",
                        },
                      },
                      [
                        item.link.status == 1
                          ? _c("img", {
                              staticClass: "img-left",
                              attrs: {
                                src: require("@/assets/image/application/关闭白.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.closeItem(item)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            )
          }),
          0
        ),
      ]),
      _c("form-config", {
        attrs: {
          applicationId: _vm.appId,
          visible: _vm.formConfigDiglogVisible,
        },
        on: {
          "update:visible": function ($event) {
            _vm.formConfigDiglogVisible = $event
          },
          success: _vm.getAllData,
        },
      }),
      _c("flow-config", {
        attrs: {
          applicationId: _vm.appId,
          visible: _vm.flowConfigDialogVisible,
          flowConfig: _vm.currFlowData,
          flowId: _vm.currFlowData.id,
        },
        on: {
          "update:visible": function ($event) {
            _vm.flowConfigDialogVisible = $event
          },
          "update:flowConfig": function ($event) {
            _vm.currFlowData = $event
          },
          "update:flow-config": function ($event) {
            _vm.currFlowData = $event
          },
          saved: _vm.getAllData,
        },
      }),
      _c("flow-base-info-config", {
        attrs: {
          applicationId: _vm.appId,
          visible: _vm.flowBaseInfoConfigDialogVisible,
          flowConfig: _vm.currFlowData,
        },
        on: {
          "update:visible": function ($event) {
            _vm.flowBaseInfoConfigDialogVisible = $event
          },
          "update:flowConfig": function ($event) {
            _vm.currFlowData = $event
          },
          "update:flow-config": function ($event) {
            _vm.currFlowData = $event
          },
          saved: _vm.getAllData,
        },
      }),
      _c("report-config", {
        attrs: {
          visible: _vm.reportConfigDiglogVisible,
          applicationId: _vm.appId,
          reportId: _vm.reportId,
          releaseId: _vm.releaseId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.reportConfigDiglogVisible = $event
          },
          success: _vm.getAllData,
        },
      }),
      _c("report-config-copy", {
        attrs: {
          visible: _vm.reportConfigCopyDiglogVisible,
          applicationId: _vm.appId,
          reportId: _vm.reportId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.reportConfigCopyDiglogVisible = $event
          },
          success: _vm.getAllData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }