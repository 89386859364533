
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ReportData, ReportModel } from '@/models/report/ReportModel';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import ReportApi from '@/api/report-design/ReportApi';
import AppApi from '@/api/application/AppApi';
import EleUpload from '../form-design/elements/ele-upload.vue';

@Component({
  name: 'ReportConfig',
  components: { EleUpload }
})
export default class ReportConfig extends Vue {
  // 应用id
  @Prop({ default: '' }) applicationId!: string;

  // 编辑时，打印模板id
  @Prop({ default: '' }) reportId!: string;

  @Prop({ default: false })
  visible!: boolean; // 流程设置弹框 的显示隐藏

  reportData: ReportModel = SystemUtil.cloneDeep(ReportData); // 流程模板配置
  reportFileIds: any = []; // 上传组件对应的文件ids
  reportLinkWords: any[] = []; // 模板关联对象的字段
  reportFileWords: any = []; // 模板文件中的文本域
  reportFileAccepts: any = ['.doc', '.docx'];
  reportUsedOptions: any[] = []; // 模板用途映射项
  dialogVisiable: boolean = false; // 是否显示页面

  created(): void {
    this.initData();
  }

  initData() {
    // 如果是编辑，获取详情
    if (this.reportId) {
      this.getReportDetail(this.reportId);
    } else {
      this.reportData = SystemUtil.cloneDeep(ReportData);
      this.reportData.applicationId = this.applicationId;
      this.reportFileIds = [];
    }
  }

  /**
   * 获取配置详情
   */
  getReportDetail(reportId) {
    ReportApi.getReportDetail({ applicationPrintId: reportId }).then((res: any) => {
      if (res.code == '1') {
        this.reportData = res.data;
        if (res.data.configuration && res.data.configuration.templateFileId) {
          this.reportFileIds = [res.data.configuration.templateFileId];
        }
        this.changeReportType(this.reportData.linkType);
      } else {
        this.reportData = SystemUtil.cloneDeep(ReportData);
      }
    });
  }

  /**
   * 改变配置用途（1：表单，2：流程）时，获取配置表单列表或流程列表
   */
  changeReportType(val) {
    let params = {
      applicationId: this.applicationId,
      linkType: val
    };
    AppApi.getApplicationLinkDataList(params).then((res: any) => {
      if (res.code == '1') {
        this.reportUsedOptions = res.data;
      }
    });
  }

  /**
   * 配置关联对象树的格式化
   */
  normalizerHandler(node) {
    let tmp: any = {
      id: node.code,
      label: node.text
    };
    if (node.children && node.children.length) {
      tmp.children = node.children;
    } else {
      tmp.children = undefined;
      tmp.isDisabled = node.disabled;
    }
    return tmp;
  }

  /**
   * 上传模板文件成功后
   */
  uploadReportTemplate(fileList) {
    let fileNames: string[] = [];
    let fileIds: string[] = [];
    fileList.forEach(item => {
      fileNames.push(item.fileName);
      fileIds.push(item.id);
    });
    if (fileIds && fileIds.length) {
      this.reportFileIds = fileIds;
      this.reportData.configuration.templateFileId = fileIds[0];
    } else {
      this.reportFileIds = [];
      this.reportData.configuration.templateFileId = '';
    }
  }

  /**
   * 保存配置
   */
  copyConfig() {
    ReportApi.copyReport(this.reportData).then((res: any) => {
      if (res.code == '1') {
        this.$message.success(res.message);
        this.dialogVisiable = false;
        this.$emit('success', {});
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 关闭弹窗
   */
  handleColseDialog() {
    this.$emit('update:visible', false);
  }

  @Watch('visible')
  visibleWatcher(val) {
    // 弹窗显示
    if (val) {
      // 获取表单列表
      this.dialogVisiable = val;
      this.initData();
    } else {
      this.dialogVisiable = val;
    }
  }
}
