














































































































































































































































































































































































































































































































import { Component, Prop, ProvideReactive, Vue, Watch } from 'vue-property-decorator';

import AppApi from '@/api/application/AppApi';
import ReportApi from '@/api/report-design/ReportApi';
import { getFormConfig } from '@/api/form-design/FormApi';
import { FlowData, FlowModel } from '@/models/flow/FlowModel';
import StoreIndex from '@/store/StoreIndex';
import SystemUtil from 'global-ui/packages/utils/SystemUtil';
import DateUtil from 'global-ui/packages/utils/DateUtil';

// 组件
import FormConfig from '@/components/form-design/config/form-config.vue';
import FlowConfig from '@/components/flow-design/flow-config/flow-config.vue';
import FlowBaseInfoConfig from '@/components/flow-design/flow-config/flow-base-info-config.vue';
import ReportConfig from '@/components/report-design/report-config.vue';
import ReportConfigCopy from '@/components/report-design/report-config-copy.vue';

@Component({
  name: 'RightCon',
  components: {
    FormConfig,
    FlowConfig,
    FlowBaseInfoConfig,
    ReportConfig,
    ReportConfigCopy
  }
})
export default class RightCon extends Vue {
  DateUtil: any = DateUtil;

  // 表单列表
  formList: any = [];
  // 流程列表
  flowList: any = [];
  // 打印配置列表
  reportList: any = [];
  // 表单配置弹窗
  formConfigDiglogVisible: boolean = false;
  // 流程配置弹窗
  flowConfigDialogVisible: boolean = false;
  // 流程基本信息配置弹窗
  flowBaseInfoConfigDialogVisible: boolean = false;
  releaseId: string = '';
  reportId: string = '';
  reportConfigDiglogVisible: boolean = false;
  reportConfigCopyDiglogVisible: boolean = false;

  liColorS: any = [
    'linear-gradient(90deg, #BA7AE8, #8364E2)',
    'linear-gradient(90deg, #699CF1, #4059E3)',
    'linear-gradient(90deg, #25B8EF, #2B70D1)',
    'linear-gradient(90deg, #3E4E89, #353065)'
  ];

  // 当前流程实体
  currFlowData: FlowModel = SystemUtil.cloneDeep(FlowData);

  @ProvideReactive() formId: any = null;
  // 应用id
  @Prop({ default: '' }) appId!: string;

  @Watch('appId')
  handlerCreated(val) {
    if (val) {
      this.getFormListData();
      this.getFlowListData();
      this.getReportListData();
    }
  }

  created() {
    this.getFormListData();
    this.getFlowListData();
    this.getReportListData();
  }

  /**
   * 获取应用表单列表
   */
  getFormListData() {
    AppApi.getApplicationLinkFormList({ applicationId: this.appId }).then(
      (res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.formList = res.data;
        } else {
          this.$message.error(res.message);
        }
      }
    );
  }

  /**
   * 获取应用流程列表
   */
  getFlowListData() {
    AppApi.getApplicationLinkProcessList({ applicationId: this.appId }).then(
      (res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.flowList = res.data;
        } else {
          this.$message.error(res.message);
        }
      }
    );
  }

  /**
   * 获取打印模板
   */
  getReportListData() {
    AppApi.getApplicationLinkReportList({ applicationId: this.appId }).then(
      (res: Record<any, any>) => {
        if (res.code == '1' && res.data) {
          this.reportList = res.data;
        } else {
          this.$message.error(res.message);
        }
      }
    );
  }
  /**
   * 新建表单或流程
   */
  addData(val) {
    if (val === 'form') {
      this.formId = '';
      this.formConfigDiglogVisible = true;
    } else if (val === 'flow') {
      this.currFlowData = SystemUtil.cloneDeep(FlowData);
      this.flowConfigDialogVisible = true;
    } else if (val === 'report') {
      this.reportId = '';
      this.reportConfigDiglogVisible = true;
    }
  }

  /**
   * 编辑表单
   * @param row
   */
  editForm(row) {
    getFormConfig({ id: row.link.id }).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        // 表单
        this.formId = row.link.id;
        StoreIndex.commit('SET_FORM_CONFIG', res.data);
        this.$nextTick(() => {
          this.formConfigDiglogVisible = true;
        });
      }
    });
  }
  /**
   * 设计表单
   * @param row
   */
  designForm(row) {
    this.$router.push({
      path: '/form/design',
      query: { formId: row.link.id, applicationId: this.appId, linkId: row.id }
    });
  }
  /**
   * 历史版本
   * @param row
   */
  historyForm(row: any, string?: string) {
    this.$router.push({
      path: '/form/history',
      query: { lid: row.id, applicationId: this.appId, type: string }
    });
  }
  /**
   * 升版
   * @param row
   */
  upgradeForm(row) {
    if (row) {
      this.$confirm(
        this.$t('lang_are_you_sure_to_upgrade') as any,
        this.$t('lang_tips') as any,
        {
          confirmButtonText: this.$t('lang_determine_') as any,
          cancelButtonText: this.$t('lang_cancel_') as any,
          type: 'warning'
        }
      ).then(() => {
        AppApi.upgradeAppLink(row.id)
          .then((res: Record<any, any>) => {
            if (res.code == '1') {
              this.getAllData();
            } else {
              this.$message.error(res.message);
            }
          })
          .finally(() => {});
      });
    }
  }
  /**
   * 删除表单
   * @param row
   */
  deleteForm(row) {
    if (row) {
      this.$confirm(
        this.$t('lang_whether_or_not_to_delete') as any,
        this.$t('lang_tips') as any,
        {
          confirmButtonText: this.$t('lang_determine_') as any,
          cancelButtonText: this.$t('lang_cancel_') as any,
          type: 'warning'
        }
      )
        .then(() => {
          AppApi.deleteAppLink(row.id)
            .then((res: Record<any, any>) => {
              if (res.code == '1') {
                this.$message.success(res.message);
                this.getFormListData();
              } else {
                this.$message.error(res.message);
              }
            })
            .finally(() => {});
        })
        .catch(() => {});
    }
  }

  /**
   * 发布表单
   * @param row
   */
  releaseForm(row) {
    this.$confirm(
      this.$t('lang_are_you_sure_to_release_it') as string,
      this.$t('lang_tips') as string,
      {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      }
    ).then(() => {
      AppApi.publishAppLink(row.id).then((res: Record<string, any>) => {
        if (res.code == '1') {
          this.getAllData();
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }
  closeItem(row) {
    this.$confirm(
      this.$t('lang_are_you_sure_closed') as string,
      this.$t('lang_tips') as string,
      {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      }
    ).then(() => {
      AppApi.closedAppLink(row.id).then((res: Record<string, any>) => {
        console.log(res);
        if (res.code == '1') {
          this.getAllData();
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }
  /**
   * 复制应用link
   * @param row
   */
  copyApplicationLink(row) {
    this.$confirm(this.$t('lang_copy_data') as string, this.$t('lang_tips') as string, {
      confirmButtonText: this.$t('lang_determine_') as string,
      cancelButtonText: this.$t('lang_cancel_') as string,
      type: 'warning'
    }).then(() => {
      let params = {
        id: row.id,
        applicationId: row.applicationId
      };
      AppApi.copyAppLink(params).then((res: Record<string, any>) => {
        if (res.code == '1') {
          this.getAllData();
        } else {
          this.$message.error(res.message);
        }
      });
    });
  }

  designPrint(row) {
    this.$router.push({
      path: '/printer/design',
      query: { formId: row.link.id, applicationId: this.appId, linkId: row.id }
    });
  }

  /**---------------流程------------- */
  /**
   * 编辑流程基础信息
   * @param row
   */
  editFlowBaseInfo(row) {
    this.currFlowData = row.link;
    if (this.currFlowData) {
      this.currFlowData.id = row.link.id;
    }
    this.flowBaseInfoConfigDialogVisible = true;
  }
  /**
   * 编辑流程
   * @param row
   */
  editFlow(row) {
    this.currFlowData = row.link;
    if (this.currFlowData) {
      this.currFlowData.id = row.link.id;
    }
    this.flowConfigDialogVisible = true;
  }
  /**
   * 流程设计
   * @param row
   */
  designFlow(row) {  
      this.$router.push({
        path: '/flow/design',
        query: {
          id: row.link.id,
          applicationId: this.appId
        }
      }); 
  }

  /**
   * 升级流程
   */
  upgradeFlow(row) {
    if (row) {
      this.$confirm(
        this.$t('lang_are_you_sure_to_upgrade') as any,
        this.$t('lang_tips') as any,
        {
          confirmButtonText: this.$t('lang_determine_') as any,
          cancelButtonText: this.$t('lang_cancel_') as any,
          type: 'warning'
        }
      ).then(() => {
        AppApi.upgradeAppLink(row.id).then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.getAllData();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    }
  }
  /**
   * 发起流程
   * @param row
   */
  startupFlow(row) {
    this.$router.push({
      path: `/flow/start`,
      query: {
        id: row.link.id
      }
    });
  }
  /**
   * 显示流程历史数据
   * @param row
   */
  historyFlow(row) {
    this.$router.push({
      path: '/flow/history',
      query: { lid: row.id, applicationId: this.appId }
    });
  }

  /**
   * 删除流程
   * @param row
   */
  deleteFlow(row) {
    if (row) {
      this.$confirm(
        this.$t('lang_whether_or_not_to_delete') as any,
        this.$t('lang_tips') as any,
        {
          confirmButtonText: this.$t('lang_determine_') as any,
          cancelButtonText: this.$t('lang_cancel_') as any,
          type: 'warning'
        }
      )
        .then(() => {
          AppApi.deleteAppLink(row.id)
            .then((res: Record<any, any>) => {
              if (res.code == '1') {
                this.$message.success(res.message);
                this.getFlowListData();
              } else {
                this.$message.error(res.message);
              }
            })
            .finally(() => {});
        })
        .catch(() => {});
    }
  }

  /**---------------打印配置------------- */

  /**
   * 编辑流程
   * @param row
   */
  editReport(row) {
    this.reportId = row.link.id;
    this.releaseId = row.id;
    this.reportConfigDiglogVisible = true;
  }

  /**
   * 复制应用link
   * @param row
   */
  copyReport(row) {
    this.reportId = row.id;
    this.reportConfigCopyDiglogVisible = true;
  }

  /**
   * 删除流程
   * @param row
   */
  removeReport(row) {
    if (row) {
      this.$confirm(
        this.$t('lang_whether_or_not_to_delete') as any,
        this.$t('lang_tips') as any,
        {
          confirmButtonText: this.$t('lang_determine_') as any,
          cancelButtonText: this.$t('lang_cancel_') as any,
          type: 'warning'
        }
      ).then(() => {
        AppApi.deleteAppLink(row.id).then((res: Record<any, any>) => {
          // ReportApi.removeReport({ applicationPrintId: row.id }).then((res: Record<any, any>) => {
          if (res.code == '1') {
            this.$message.success(res.message);
            this.getReportListData();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    }
  }
  getAllData() {
    this.getReportListData();
    this.getFlowListData();
    this.getFormListData();
  }
}
