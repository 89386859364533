var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hg-100-percent" },
    [
      _c(
        "glb-spliter",
        {
          attrs: {
            gutter: 10,
            spanList: [
              { span: 5, slot: "left" },
              { span: 19, slot: "right" },
            ],
          },
        },
        [
          _c("template", { slot: "left" }, [
            _c("div", { staticClass: "main-left-tree-wrapper" }, [
              _c("div", { staticClass: "main-left-tree-header" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("lang_application_management"))),
                ]),
                _vm.rowData
                  ? _c(
                      "div",
                      { staticClass: "left-tree-top-button" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.$t("lang_configuration_range"),
                              placement: "bottom",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-s-tools",
                              staticStyle: { "margin-right": "1.3vw" },
                              on: {
                                click: function ($event) {
                                  return _vm.appConfigScopeFn()
                                },
                              },
                            }),
                          ]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: _vm.$t("lang_copy_application"),
                              placement: "bottom",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-document-copy",
                              staticStyle: { "margin-right": "1.3vw" },
                              on: {
                                click: function ($event) {
                                  return _vm.showCopyAppFn()
                                },
                              },
                            }),
                          ]
                        ),
                        _c("i", {
                          staticClass: "el-icon-plus",
                          attrs: { title: _vm.$t("lang_new_application_type") },
                          on: {
                            click: function ($event) {
                              return _vm.addAppForm()
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _c("i", {
                      staticClass: "el-icon-plus",
                      attrs: { title: _vm.$t("lang_new_application_type") },
                      on: {
                        click: function ($event) {
                          _vm.curAppTypeId ? _vm.addAppForm() : _vm.addAppType()
                        },
                      },
                    }),
              ]),
              _c(
                "div",
                { staticClass: "main-left-tree-content" },
                [
                  _c("el-input", {
                    staticClass: "main-left-tree-filter mgt-10",
                    attrs: {
                      placeholder: _vm.$t("lang_enter_keywords_to_filter"),
                      clearable: "",
                    },
                    on: { clear: _vm.getAppTypeTreeData },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.getAppTypeTreeData($event)
                      },
                    },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                  _c("el-tree", {
                    ref: "appTreeRef",
                    staticClass: "main-left-tree-instance",
                    attrs: {
                      data: _vm.appData,
                      "default-expand-all": "",
                      "expand-on-click-node": false,
                      "highlight-current": "",
                      "node-key": "id",
                    },
                    on: { "node-click": _vm.clickTreeNode },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var data = ref.data
                          return [
                            _c(
                              "span",
                              { staticClass: "custom-tree-node tree-span" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "custom-tree-node-title" },
                                  [_vm._v(_vm._s(_vm.$t(data.name)))]
                                ),
                                data.id != -1
                                  ? _c(
                                      "span",
                                      [
                                        _c("el-button", {
                                          staticClass: "custom-tree-node-btn",
                                          attrs: {
                                            type: "text",
                                            size: "mini",
                                            icon: "el-icon-edit",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.editAppType(data)
                                            },
                                          },
                                        }),
                                        _c("el-button", {
                                          staticClass: "custom-tree-node-btn",
                                          staticStyle: { color: "red" },
                                          attrs: {
                                            type: "text",
                                            icon: "el-icon-delete",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.removeAppType(data)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "template",
            { slot: "right" },
            [
              _vm.rightConId
                ? _c("RightCon", { attrs: { appId: _vm.rightConId } })
                : _c("div", { staticClass: "empty-img" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/image/front-frame/暂无任务1.png"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v(_vm._s(_vm.$t("lang_no_data")))]),
                  ]),
            ],
            1
          ),
        ],
        2
      ),
      _c("app-type-form", {
        attrs: {
          visible: _vm.appTypeDiglogVisible,
          "app-type-id": _vm.curAppTypeId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.appTypeDiglogVisible = $event
          },
          close: _vm.closeAppTypeForm,
        },
      }),
      _c("app-form", {
        attrs: {
          visible: _vm.appDiglogVisible,
          "app-id": _vm.curAppId,
          "app-type-id": _vm.curAppTypeId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.appDiglogVisible = $event
          },
          close: _vm.getAppListByAppTypeId,
          getTreeData: _vm.getAppTypeTreeData,
        },
      }),
      _c("app-config-scope", {
        attrs: {
          visible: _vm.appConfigScopeDiglogVisible,
          "app-id": _vm.curAppId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.appConfigScopeDiglogVisible = $event
          },
          close: _vm.getAppListByAppTypeId,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.appTypeTreeVisibal,
            width: "70%",
            "close-on-click-modal": false,
            "destroy-on-close": true,
            center: true,
            title: _vm.$t("lang_select_application_type"),
          },
          on: {
            "update:visible": function ($event) {
              _vm.appTypeTreeVisibal = $event
            },
          },
        },
        [
          _c("app-type-tree", {
            attrs: { change: "" },
            model: {
              value: _vm.copyAppTypeId,
              callback: function ($$v) {
                _vm.copyAppTypeId = $$v
              },
              expression: "copyAppTypeId",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.copyApp()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_save_")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.appTypeTreeVisibal = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }