

























































































































































import AppTypeApi from '@/api/application/AppTypeApi';
import AppApi from '@/api/application/AppApi';
import { Component, Vue } from 'vue-property-decorator';
import AppTypeForm from './app-type-form.vue';
import AppForm from './app-form.vue';
import AppConfigScope from './app-config-scope.vue';
import AppTypeTree from './app-type-tree.vue';
import RightCon from './components/right-con.vue';

@Component({
  name: 'AppList',
  components: {
    AppTypeForm,
    AppConfigScope,
    AppForm,
    AppTypeTree,
    RightCon
  }
})
export default class AppList extends Vue {
  rowData: any = null; //点击左侧树暂存 该节点数据
  rightConId: any = null;
  // 表单搜索Model
  formSearchModel: any = { name: '' };

  // 应用列表
  formList: any[] = [];

  // 应用树prop
  appTreeProp: Record<string, string> = {
    id: 'id',
    label: 'displayName',
    children: 'children'
  };
  // 应用树列表
  appData: any[] = [];
  // 应用树过滤文本框内容
  filterText: string = '';

  // 分页参数
  formPage: any = {
    pageNo: 1,
    pageSize: 10,
    total: 0
  };

  // 是否显示应用配置范围弹窗
  appConfigScopeDiglogVisible: boolean = false;
  // 是否显示应用类型弹窗
  appTypeDiglogVisible: boolean = false;
  // 是否显示应用编辑新增弹窗
  appDiglogVisible: boolean = false;
  // 复制应用时弹出的appType选择
  appTypeTreeVisibal: boolean = false;
  // 左侧应用类型树当前选中的应用类型id
  copyAppTypeId: string = '';
  curAppTypeId: string = '';
  // 应用id
  curAppId: string = '';

  get _clientWidth() {
    return this.$store.getters.clientWidth;
  }

  /**
   * 新增app类型
   */
  addAppType() {
    this.curAppTypeId = '';
    this.appTypeDiglogVisible = true;
  }

  /**
   * 编辑app
   * @param app
   */
  editAppType(app) {
    // 如果有applicationTypeId 表示左侧数据的编辑
    if (app.applicationTypeId) {
      this.editForm(app);
    } else {
      this.curAppTypeId = app.id;
      this.appTypeDiglogVisible = true;
    }
  }

  /**
   * 应用配置范围
   * @param row
   */
  appConfigScope(row) {
    if (row) {
      this.curAppId = row.id;
      this.appConfigScopeDiglogVisible = true;
    }
  }
  appConfigScopeFn() {
    this.appConfigScope(this.rowData);
  }

  /**
   * 进入应用
   * @param row
   */
  enterApp(row) {
    if (row) {
      this.curAppId = row.id;
      this.$router.push({ name: 'AppDetail', query: { appId: this.curAppId } });
    }
  }

  /**
   * 进入应用
   * @param row
   */
  showCopyApp(app) {
    if (app) {
      this.appTypeTreeVisibal = true;
      this.curAppId = app.id;
    }
  }
  showCopyAppFn() {
    this.showCopyApp(this.rowData);
  }

  copyApp() {
    this.$confirm(
      this.$t('lang_copy_app_confirm') as string,
      this.$t('lang_tips') as string,
      {
        confirmButtonText: this.$t('lang_determine_') as string,
        cancelButtonText: this.$t('lang_cancel_') as string,
        type: 'warning'
      }
    )
      .then(() => {
        // 加载框
        AppApi.copy({ id: this.curAppId, toApplicationTypeId: this.copyAppTypeId })
          .then((res: Record<any, any>) => {
            if (res.code == '1') {
              this.$message.success(res.message);
              // 获取列表
              this.getAppListByAppTypeId();
            } else {
              this.$message.error(res.message);
            }
          })
          .finally(() => {});
      })
      .catch(() => {});
  }

  /**
   * 删除app
   * @param app
   */
  removeApp(app) {
    if (app) {
      this.curAppId = app.id;

      this.$confirm(
        this.$t('lang_delete_app_continue') as string,
        this.$t('lang_tips') as string,
        {
          confirmButtonText: this.$t('lang_determine_') as string,
          cancelButtonText: this.$t('lang_cancel_') as string,
          type: 'warning'
        }
      )
        .then(() => {
          // 加载框
          AppApi.delete({ applicationId: this.curAppId })
            .then((res: Record<any, any>) => {
              if (res.code == '1') {
                this.$message.success(res.message);
                // 获取列表
                this.getAppListByAppTypeId();
                this.getAppTypeTreeData();
              } else {
                this.$message.error(res.message);
              }
            })
            .finally(() => {});
        })
        .catch(() => {});
    }
  }

  /**
   * 删除应用类型
   */
  removeAppType(row) {
    if (row.applicationTypeId) {
      this.removeApp(row);
    } else {
      this.curAppTypeId = row.id;

      this.$confirm(
        this.$t('lang_whether_or_not_to_delete') as string,
        this.$t('lang_tips') as string,
        {
          confirmButtonText: this.$t('lang_determine_') as string,
          cancelButtonText: this.$t('lang_cancel_') as string,
          type: 'warning'
        }
      )
        .then(() => {
          AppTypeApi.delete({ applicationTypeId: this.curAppTypeId })
            .then((res: Record<any, any>) => {
              if (res.code == '1') {
                this.$message.success(res.message);
                // 获取列表
                // this.getAppTypeTreeData();
                this.getAppTypeTreeData();
              } else {
                this.$message.error(res.message);
              }
            })
            .finally(() => {});
        })
        .catch(() => {});
    }
  }

  /**
   * 应用树节点点击事件
   * @param data
   */
  clickTreeNode(data) {
    if (data) {
      this.rowData = null;
      this.rightConId = null;
      if (data.id === '-1') {
        this.curAppTypeId = '';
        return;
      }
      if (data.applicationTypeId) {
        this.rowData = data;
        this.rightConId = data.id;
        sessionStorage.setItem('applicationId', this.rightConId);
        return;
      }
      this.curAppTypeId = data.id;
      // 根据AppTypeId获取App列表
      this.getAppListByAppTypeId();
    }
  }

  /**
   * 根据AppTypeId获取App列表
   */
  getAppListByAppTypeId() {
    let param = {
      applicationTypeId: this.curAppTypeId,
      name: this.formSearchModel.name,
      page: {
        pageIndex: this.formPage.pageNo,
        pageSize: this.formPage.pageSize
      }
    };
    AppApi.page(param).then((res: Record<any, any>) => {
      if (res.code == '1') {
        this.formList = res.data.list;
        this.formPage.total = res.data.total;
      } else {
        this.$message.error(res.message);
      }
    });
  }

  /**
   * 搜索
   */
  handleSearch() {
    this.formPage.pageNo = 1;
    this.getAppListByAppTypeId();
  }

  /**
   * 重置搜索
   */
  handleSearchReset() {
    this.formPage.pageNo = 1;
    this.formPage.pageSize = 10;
    this.formSearchModel.name = '';
    this.getAppListByAppTypeId();
  }

  /**
   * 新增应用表单弹窗
   */
  addAppForm() {
    if (this.curAppTypeId) {
      if (this.curAppTypeId === '-1') {
        this.$message.error(this.$t('lang_unselected_root_node') as any);
        return;
      }

      this.curAppId = '';
      this.appDiglogVisible = true;
    }
  }

  // 编辑
  editForm(row) {
    if (row) {
      this.curAppId = row.id;
      this.appDiglogVisible = true;
    }
  }

  /**
   * 跳转至某页
   */
  changePageNo(page) {
    this.formPage.pageNo = page;

    // 根据AppTypeId获取App列表
    this.getAppListByAppTypeId();
  }

  /**
   * 跳转至某页
   */
  changePageSize(pageSize) {
    this.formPage.pageNo = 1;
    this.formPage.pageSize = pageSize;

    // 根据AppTypeId获取App列表
    this.getAppListByAppTypeId();
  }

  toForm() {
    this.$router.push('/form/list');
  }

  toFlow() {
    this.$router.push('/flow/list');
  }

  closeAppTypeForm() {
    this.curAppTypeId = '';
    this.appTypeDiglogVisible = false;
    this.getAppTypeTreeData();
  }

  /**
   * 获取左侧应用树列表
   */
  getAppTypeTreeData() {
    let params = {
      name: this.filterText
    };
    AppTypeApi.tree(params).then((res: Record<any, any>) => {
      if (res.code == '1' && res.data) {
        let data = res.data;
        // 判断是否有选中的AppTypeId如果没有则取数组第一个
        let curAppTypeId = this.curAppTypeId;
        if (!curAppTypeId) {
          if (data.length > 0) {
            this.curAppTypeId = data[0].id;
          }
        }
        data.forEach((a) => (a.name = a.displayName));

        // 将applications也放到 children中（applications下没有children,children下可能有children）
        this.recursionData(data);

        this.appData = [{ id: '-1', name: this.$t('lang_top_node'), children: data }];

        // 获取应用列表
        // this.getAppListByAppTypeId();
      } else {
        this.$message.error(res.message);
      }
    });
  }
  recursionData(data: any = []): void {
    if (data.length) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].children && data[i].children.length) {
          this.recursionData(data[i].children);
        }
        if (data[i].applications.length && data[i].children) {
          data[i].children.push(...data[i].applications);
        } else if (data[i].applications.length) {
          data[i].children = [];
          data[i].children = data[i].applications;
        }
      }
    }
  }

  /**
   * created
   */
  created() {
    // 获取应用类型列表
    this.getAppTypeTreeData();
    let designId = sessionStorage.getItem('designId');
    if (designId && JSON.parse(designId).applicationId) {
      this.rightConId = JSON.parse(designId).applicationId;
      sessionStorage.removeItem('designId');
    }
  }
}
