var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.$t("lang_template_settings"),
        visible: _vm.dialogVisiable,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        "custom-class": "report-dialog",
        width: "60%",
        top: "5vh",
      },
      on: { close: _vm.handleColseDialog },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "flow-config-form",
          attrs: { "label-width": "100px" },
        },
        [
          _c("el-divider", { staticClass: "cus-divider" }, [
            _vm._v(_vm._s(_vm.$t("lang_bsic_information"))),
          ]),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("lang_purpose_of_template") } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.changeReportType },
                          model: {
                            value: _vm.reportData.linkType,
                            callback: function ($$v) {
                              _vm.$set(_vm.reportData, "linkType", $$v)
                            },
                            expression: "reportData.linkType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v(_vm._s(_vm.$t("lang_form"))),
                          ]),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("lang_technological_process"))
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("lang_template_object") } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "no-data-text": _vm.$t("lang_no_data"),
                            placeholder: _vm.$t("lang_please_select"),
                          },
                          model: {
                            value: _vm.reportData.applicationLinkId,
                            callback: function ($$v) {
                              _vm.$set(_vm.reportData, "applicationLinkId", $$v)
                            },
                            expression: "reportData.applicationLinkId",
                          },
                        },
                        _vm._l(_vm.reportUsedOptions, function (option) {
                          return _c("el-option", {
                            key: option.id,
                            attrs: {
                              label: option.link.displayName,
                              value: option.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("lang_template_file") } },
                    [
                      _c("ele-upload", {
                        staticClass: "upload-demo",
                        staticStyle: { width: "100%" },
                        attrs: { accept: _vm.reportFileAccepts, limit: 1 },
                        on: { success: _vm.uploadReportTemplate },
                        model: {
                          value: _vm.reportFileIds,
                          callback: function ($$v) {
                            _vm.reportFileIds = $$v
                          },
                          expression: "reportFileIds",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.handleColseDialog } }, [
            _vm._v(_vm._s(_vm.$t("lang_cancel_"))),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.copyConfig } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }